/* global jsVars */
import ajax from '../helpers/ajax';

const btnReliveConf = document.querySelector('.relive-load-more');
const wrapperReliveConf = document.getElementById('home-reliveconf');

window.addEventListener('load', function () {
	if (typeof btnReliveConf !== 'undefined' && btnReliveConf != null) {
		btnReliveConf.addEventListener('click', (e) => {
			e.preventDefault();
			const page = btnReliveConf.getAttribute('data-page');
			const formData = new FormData();

			formData.append('action', 'load_more_post_reliveconf');
			formData.append('nonce', jsVars.nonce);
			formData.append('page', page);

			btnReliveConf.textContent = 'CARGANDO...';
			ajax({
				url: jsVars.ajax_url,
				method: 'POST',
				params: formData,
				async: true,
				done: function (response) {
					if (response.status) {
						wrapperReliveConf.innerHTML += response.html;
					}
					if (!response.enabled) {
						btnReliveConf.classList.add('hide');
					}
					btnReliveConf.dataset.page = response.page;
				},
				error: function (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				},
				always: function () {
					btnReliveConf.textContent = 'conoce más novedades';
				},
			});
		});
	}
});
